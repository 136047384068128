<template>
  <div>
    <a-card>
      <a-row style="margin-top:16px">
        <a-col :sm="{ span: 12, offset: 6 }" :xs="{ span: 20, offset: 2 }">
          <a-steps :current="current">
            <a-step title="填写信息" />
            <a-step title="创建阶段" />
          </a-steps>
        </a-col>
      </a-row>

      <basis-form v-show="current === 0" ref="skill" style="margin-top:80px"></basis-form>
      <skill-phase-form
        v-show="current === 1"
        ref="phase"
        :fieldId="fieldId"
        style="margin-top:80px"
      ></skill-phase-form>

      <a-row>
        <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
          <a-space>
            <a-button :loading="loading" type="primary" shape="round" style="width:160px" @click="onSubmit">
              {{ ['下一步', '保存'][current] }}
            </a-button>
            <a-button v-if="current === 1" :loading="loading" shape="round" style="width:160px" @click="handleEdit">
              上一步
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import events from '@/components/MultiTab/events'
import * as skillApi from '@/api/skill'
import { scrollTo } from '@/utils/domUtil'
import BasisForm from './components/BasisForm'
import SkillPhaseForm from './phase/components/BasisForm'

export default {
  name: 'SkillManagementSkillCreate',
  components: {
    BasisForm,
    SkillPhaseForm
  },
  data() {
    return {
      current: 0,
      loading: false,
      id: undefined,
      mainFieldId: NaN,
      fieldId: NaN
    }
  },
  methods: {
    handleEdit() {
      this.current = 0
      scrollTo(document.documentElement, 0, 128)
    },
    onSubmit() {
      const {
        $refs: { skill, phase },
        $notification,
        current,
        close
      } = this

      const curentForm = [skill, phase][current]

      curentForm.validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = curentForm
            const form_ = formatForm()

            console.log(form_)

            if (current === 0) {
              this.skillForm = form_
              this.fieldId = form_.fieldId
              this.current = 1
              this.loading = false
              scrollTo(document.documentElement, 0, 128)
              // skillApi
              //   .create(form_)
              //   .then(res => {
              //     $notification['success']({ message: '操作成功' })
              //     const {
              //       id,
              //       field: {
              //         id: fieldId,
              //         parent: { id: mainFieldId }
              //       }
              //     } = res.data
              //     this.id = id
              //     this.mainFieldId = mainFieldId
              //     this.fieldId = fieldId
              //   })
              //   .finally(() => (this.loading = false))
            } else if (current === 1) {
              skillApi
                .create(this.skillForm)
                .then(res => {
                  const { id: skillId } = res.data

                  skillApi
                    .createPhase({ ...form_, skillId })
                    .then(res => {
                      $notification['success']({ message: '操作成功' })
                      close()
                    })
                    .finally(() => (this.loading = false))
                })
                .catch(() => {
                  this.loading = false
                })
            }
          } catch (error) {
            console.log(error)
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped></style>
